<template>
  <div class="page1">
    <div class="info" v-if="closeShow">
      <el-row>
        <el-col :span="24">
          <div class="info_content">
            <el-col :span="23">
              <span
                >PDA统计，按支付时间统计PDA收缴的各渠道金额；适用于运营人员了解PDA收费情况。</span
              >
            </el-col>
            <el-col :span="1">
              <img src="./img/close.png" @click="closeShow = false" alt="" />
            </el-col>
          </div>
        </el-col>
      </el-row>
    </div>
    <!--主体内容-->
    <div class="content bgFFF">
      <!--搜索条件区域-->
      <div class="searchWrapper">
        <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
        <el-form :inline="true" label-position="right" :model="formInline" class="demo-form-inline">
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Merchant_Name')" prop="operationId">
                <el-select v-model.trim="formInline.operationId" filterable size="15">
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="value.operationName"
                    :value="value.operationId"
                    :key="value.operationId"
                    v-for="value in tenantList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Login_Name')" prop="account" label-width="75px">
                <el-input v-model.trim="formInline.account" placeholder="请输入内容"></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.full_name')" prop="name" label-width="60px">
                <el-input v-model.trim="formInline.name" placeholder="请输入内容"></el-input>
              </el-form-item>
              <el-form-item label="时间" label-width="40px">
                <a-date-picker
                  ref="datePicker"
                  :YearShowHidden="selectkeys[3]"
                  :selectkeys="selectkeys"
                  :previousDate="previousDate"
                  :nextDate="nextDate"
                ></a-date-picker>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  getData();
                  page = 1;
                "
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
            </div>
          </div>
          <div class="col_box_boder" v-if="$route.meta.authority.button.export"></div>
          <div class="col_box h44">
            <div class="col_left"></div>
            <div class="col_right mbd4">
              <el-button
                type=""
                v-if="$route.meta.authority.button.export"
                icon="el-icon-upload2"
                @click="exportFile"
                :loading="loading"
                >导出
              </el-button>
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="paddingB20">
        <!--列表区域-->
        <div class="tableWrapper paddingB20 graphShadow">
          <!-- 筛选按钮 -->
          <div class="switch">
            <screen
              :checkBoxGroup="tableCols"
              :checkedColumns="checkedColumns"
              :reportType="7"
              @selectChange="selectChange"
              @sortChange="sortChange"
            ></screen>
          </div>
          <el-table
            header-cell-class-name="header-call-style"
            v-loading="loading"
            :data="tableData"
            style="width: 100%"
            :key="tableKey"
          >
            <template v-for="(item, i) in tableCols">
              <el-table-column
                :label="item.label"
                :prop="item.prop"
                align="center"
                v-if="tableCols[i] && tableCols[i].isShow"
                :key="item.prop"
                :formatter="item.formatter"
                :min-width="item.width"
              >
              </el-table-column>
            </template>
          </el-table>
          <!--分页器-->
          <div class="pagerWrapper" v-if="total > 0">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              background
              :current-page="page"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
        <!-- <el-table
          header-cell-class-name="header-call-style"
          v-loading="loading"

          :data="tableData"
          style="width: 100%"
        >
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            align="center"
          ></el-table-column>
        </el-table>
        <div class="pagerWrapper bgFFF" v-if="total > 0">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-size="pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { dateFormat, exportExcelNew } from "../../common/js/public.js";
import screen from "@/components/screen/index";
export default {
  name: "collectorIncome",
  data() {
    let startTime = new Date();
    startTime.setTime(startTime.getTime() - 31 * 24 * 60 * 60 * 1000);
    let date = startTime;
    return {
      tableKey: 0,
      channelTypeList: [],
      page: 1,
      pageSize: 15,
      total: 0,
      index: 0,
      loading: false,
      detailFlag: false,
      closeShow: true,
      areaList: [],
      parkList: [],
      tenantList: [],
      rsTypeList: {},
      previousDate: new Date(),
      nextDate: new Date(),
      // 当前选中的多选框，代表当前展示的列
      checkedColumns: [],
      screeningNum: 0,
      selectkeys: ["date", "month", "week", "year"],
      tableCols: [],
      tableData: [],
      formInline: {
        dateType: "month",
        date: [dateFormat(date, "yyyy-MM"), dateFormat(new Date(), "yyyy-MM")],
        account: "",
        name: "",
        operationId: "",
        // pdaManagerState: 1,
        day: [dateFormat(date, "yyyy-MM-dd"), dateFormat(new Date(), "yyyy-MM-dd")],
        diffType: 0,
      },
    };
  },
  watch: {},
  methods: {
    selectChange(selectList) {
      this.tableCols.filter((i, index) => {
        i.isShow = false;
      });
      selectList.forEach((i) => {
        this.tableCols[i].isShow = true;
      });
      this.getData();
    },
    sortChange(sortList) {
      this.tableCols = sortList;
      // 强制刷新表格
      this.tableKey += 1;
      this.getData();
    },
    getReportFieldHide() {
      this.$axios
        .get("/acb/2.0/reportFieldHide/get", {
          data: {
            reportType: 7,
          },
        })
        .then((res) => {
          if (res.value.length == 1 && res.value[0] == "") {
            this.checkedColumns = [];
          } else {
            this.checkedColumns = res.value.map(Number);
          }
          this.checkedColumns.forEach((i) => {
            this.tableCols[i].isShow = true
          });
        });
    },
    // 列表点击事件
    screeningShow() {
      this.screeningNum = 1;
    },
    screeningHide() {
      this.screeningNum = 0;
    },
    getTimeNow() {
      const endTime = new Date();
      if (this.formInline.dateType == "day") {
        endTime.setTime(endTime.getTime() - 3600 * 1000 * 24);
        this.formInline.day = [
          dateFormat(endTime, "yyyy-MM-dd"),
          dateFormat(new Date(), "yyyy-MM-dd"),
        ];
      } else {
        endTime.setTime(endTime.getTime() - 31 * 24 * 3600 * 1000);
        this.formInline.date = [dateFormat(endTime, "yyyy-MM"), dateFormat(new Date(), "yyyy-MM")];
      }
    },
    /* 商户名称的下拉框 */
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getData();
    },
    getData() {
      if (!this.formInline.dateType) {
        this.$alert("日期类型不能为空！", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      console.log(this.formInline.date);
      this.formInline.startDate = this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "";
      this.formInline.endDate = this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "";
      // let startDate = "",
      //   endDate = "";
      // if (this.formInline.dateType == "month") {
      //   startDate = this.formInline.date[0];
      //   endDate = this.formInline.date[1];
      // } else {
      //   // date = this.formInline.day;
      //   startDate = this.formInline.day[0];
      //   endDate = this.formInline.day[1];
      // }
      // let url = '/acb/2.0/financeStatistic/pdaManagers/report/' +
      //   this.formInline.dateType + '/' + date

      let url = "/acb/2.0/financeStatistic/pdaManagers/report/" + this.formInline.dateType;
      this.$axios
        .get(url, {
          data: {
            account: this.formInline.account,
            name: this.formInline.name,
            operationId: this.formInline.operationId,
            page: this.page,
            pageSize: this.pageSize,
            startDate: this.formInline.startDate,
            endDate: this.formInline.endDate,
            // pdaManagerState: this.formInline.pdaManagerState,
            reportType: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
          },
        })
        .then((res) => {
          if (res.state != 0) {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          } else {
            this.tableData = res.value.list || [];
            this.total = res.value.total * 1 || 0;
          }
        });
    },
    getChannelTypeList() {
      this.$axios.get("/acb/2.0/channelType/list").then((res) => {
        console.log(res, "res");
        this.channelTypeList = res.value;
        let arr1 = [
          {
            prop: "reportDate",
            label: this.$t("list.date"),
            width: "",
          },
          {
            prop: "operationName",
            label: this.$t("list.Merchant_Name"),
            width: "",
          },
          {
            prop: "loginName",
            label: this.$t("list.Login_Name"),
            width: "",
          },
          {
            prop: "realName",
            label: this.$t("list.full_name"),
            width: "",
          },
          {
            prop: "pdaManagerStateDesc",
            label: this.$t("list.state"),
            width: "",
          },
          {
            prop: "totalActualPay",
            label: "收费金额",
            width: "",
            formatter: (row, column, cellValue, index) => {
              return cellValue ? (cellValue / 100).toFixed(2) + "元" : `0.00元`;
            },
          },
        ];
        let arr2 = [];
        this.channelTypeList.forEach((item) => {
          arr2.push({
            prop: item.code + "",
            label: item.desc,
            width: [17, 21].includes(item.code) ? "140" : "90",
            formatter: (row, column, cellValue, index) => {
              return cellValue ? (cellValue / 100).toFixed(2) + "元" : `0.00元`;
            },
          });
        });

        this.tableCols = arr1.concat(arr2).map(item => {
          return {
           ...item,
            isShow: false,
          }
        });
        this.getReportFieldHide();
        this.getData();
      });
    },
    exportFile() {
      let startDate = this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "";
      let endDate = this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "";
      let url = "/acb/2.0/financeStatistic/pdaManagers/report/" + this.formInline.dateType;
      let opt = {
        account: this.formInline.account,
        name: this.formInline.name,
        operationId: this.formInline.operationId,
        startDate: startDate,
        endDate: endDate,
        reportType: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
      };
      exportExcelNew(url, opt);
    },
  },
  beforeDestroy() {},
  components: {
    screen,
  },
  // activated () {
  //   this.getData();
  // },
  created() {
    this.getChannelTypeList();
    this.getTenantList();
    // if (sessionStorage.searchFlag == 1) {
    //   this.formInline = JSON.parse(sessionStorage.searchData);
    // }
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
}

.info {
  position: relative;
  left: -20px;
  width: calc(100% + 20px);
  height: 40px;
  padding-left: 20px;
  line-height: 40px;
  background: rgba(254, 242, 213, 0.47);

  .info_content {
    background: rgba(254, 242, 213, 0.47);
    color: rgba(255, 151, 74, 1);
  }

  img {
    width: 24px;
    height: 24px;
    vertical-align: middle;
    cursor: pointer;
  }
}

.content {
  .but {
    float: right;
  }

  .pagerWrapper {
    text-align: right;
    margin-top: 20px;
    font-size: 12px;

    .export {
      font-size: 12px;

      .iconfont {
        margin-right: 0;
      }
    }
  }
}

.detail {
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128, 145, 165, 0.3);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;

  .title {
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;

    .el-icon-close {
      float: right;
      margin-top: 14px;
    }
  }

  .info {
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
  }
}

.mask {
  background: rgba(49, 53, 65, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.el-dropdown-link {
  cursor: pointer;
}

.tableWrapper >>> .header-call-style {
  color: rgba(0, 0, 0, 0.85) !important;
  background: #f3f7ff !important;
}

.tableWrapper {
  margin-top: 16px;
  position: relative;

  /deep/ .el-table th.el-table__cell {

  }

  .switch {
    position: absolute;
    right: 0;
    z-index: 2;
    top: 1px;
    cursor: pointer;

    img {
      height: 54px;
      width: 40px;
    }
  }
}
</style>
